import * as React from "react"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"
import { useContext } from "react"
import { Link } from "gatsby"

// component
import {
  Header,
  Footer,
  BottomNavbar,
  RecipesBlog,
  NoAuth,
  NoAccess,
  Loader,
} from "../components/index"

// Context
import UserContext from "../context/UserAuth"

// assets
import { BiArrowBack } from "react-icons/bi"

// data
import mockup from "../utils/data"

const IndexPage = ({ data }) => {
  const { isAuthenticated, isAccess, isLoading } = useContext(UserContext) || {
    isAuthenticated: false,
    isAccess: false,
    isLoading: false,
  }

  return (
    <div>
      <Helmet>
        <title>Esteekey - Recipes</title>
        <meta name="description" content="Esteekey - #1 Fitness app for 2022" />
        <meta name="theme-color" content="#ffffff" data-react-helmet="true" />
      </Helmet>
      <Header />
      <div className="min-h-[calc(100vh-158px)] pt-[69px] xl:pt-0">
        {isLoading && <Loader />}
        {!isAuthenticated ? (
          <NoAuth />
        ) : !isAccess ? (
          <NoAccess />
        ) : (
          <div>
            <RecipesBlog
              category={data.recipeTags.nodes}
              recipes={data.meals.nodes}
            />
            <div className="sm:hidden fixed bottom-20 left-2 rounded-full w-10 h-10 bg-white shadow-btn-md z-10 flex items-center justify-center">
              <Link to="/dashboard">
                <BiArrowBack />
              </Link>
            </div>
          </div>
        )}
      </div>
      <BottomNavbar activePage="recipes" />
      <Footer />
    </div>
  )
}

export const query = graphql`
  query meals {
    recipeTags: allSanityMealTime {
      nodes {
        nameRU
        name
      }
    }
    meals: allSanityMeal {
      nodes {
        _id
        nameRU
        name
        image {
          asset {
            localFile(width: 800) {
              childImageSharp {
                gatsbyImageData(
                  width: 800
                  quality: 90
                  layout: CONSTRAINED
                  placeholder: BLURRED
                )
              }
            }
          }
        }
        calories
        carbs
        fat
        protein
        mealTime {
          name
          nameRU
        }
        slug {
          current
        }
      }
    }
  }
`

export default IndexPage
